import React, {useEffect} from "react"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import "../css/404.css"

const NotFoundPage = ({path}) => {
  useEffect(()=>{
    const script = document.createElement('script');
    script.src = 'https://code.jquery.com/jquery-2.2.4.min.js'
    script.async = true;
    document.body.appendChild(script)
    const script2 = document.createElement('script');
    script2.src = 'https://cdnwest.radnet.com/404/includes/custom.js'
    script2.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script2);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);      
    }
  },[])

  return(
    <div className="four04">
    <SEO title="404" />
    <header className="mincluain-header">
      <div className="row">
        <div className="logo"> <a href="https://www.radnet.com">RadNet</a> </div>
      </div>
    </header>
    <div id="main-404-content" className="main-content-particle-js">
      <div className="content-wrap">
        <div className="shadow-overlay"></div>
        <div className="main-content">
          <div className="row">
            <div className="col-twelve">
              <h1 className="kerning">404 Error</h1>
              <p> Oops! Looks like nothing was found at this location. Please try double checking the URL.</p>
            </div>
          </div>
        </div>
        <footer>
          <div className="row">
            <div className="col-seven tab-full social-links" style={{float:`right`}}>
              <ul>
                <li><a href="https://www.facebook.com/radnetimaging" target="_blank"><i className="fa fa-facebook"></i></a></li>
                <li><a href="https://www.instagram.com/radnetimaging" target="_blank"><i className="fa fa-instagram"></i></a></li>
                <li><a href="https://www.twitter.com/radnetimaging" target="_blank"><i className="fa fa-twitter"></i></a></li>
                <li><a href="https://www.youtube.com/radnetvideos" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
              </ul>
            </div>
            <div className="col-five tab-full bottom-links">
              <ul className="links">
                <li><a href="https://www.radnet.com">Go to RadNet.com</a></li>
                <li><a href="mailto:DigitalCommunications@RADNET.COM">Report Problem</a></li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </div>

    </div>
  )
}



export default NotFoundPage
